import * as React from "react";
import Layout from "../components/Layout/layout";
import Section from "../components/Layout/Section";
import * as errorStyles from "./error.module.css";

const InternalErrorPage = () => {
    return (
        <Layout>
            <Section>
                <div className={errorStyles.error}>
                    <h1 className={errorStyles.error__title}>Приложение недоступно</h1>
                    <p className={errorStyles.error__desc}>
                        Мы проводим профилактические работы или столкнулись с&nbsp;неожиданными проблемами.
                    </p>
                    <p className={errorStyles.error__desc}>
                        Сейчас мы работаем над тем, чтобы восстановить доступ как&nbsp;можно быстрее.
                    </p>
                    <p className={errorStyles.error__desc}>
                        Пожалуйста, попробуйте обновить страницу через некоторое время. Приносим извинения за
                        доставленные неудобства!
                    </p>
                </div>
                <div>
                    <div className={`${errorStyles.error__gradient} ${errorStyles.error__gradient_light}`} />
                    <div className={`${errorStyles.error__gradient} ${errorStyles.error__gradient_light}`} />
                    <div className={`${errorStyles.error__gradient} ${errorStyles.error__gradient_light}`} />
                </div>
            </Section>
        </Layout>
    );
};

export default InternalErrorPage;
